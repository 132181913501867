<app-navbar></app-navbar>
<div>
    <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="../../../assets/img/portada.jpg" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                    <button class="btn btn-warning cat">CATÁLOGO DE PRODUCTOS</button>
                    <h4 class="mt-2">Accesorios de la mejor calidad para el trensporte de pasajaros.</h4>
                </div>
            </div>
            <div class="carousel-item">
                <img src="../../../assets/img/portada2.jpg" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                    <h3>TAXÍMETROS EDICAR</h3>
                    <h4 class="port">19 años de experiencia nos respaldan.</h4>
                    <img src="../../../assets/img/logo.png" width="50" alt="">

                </div>
            </div>
            <div class="carousel-item">
                <img src="../../../assets/img/portada3.jpg" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                    <h3>FABRICANTES DIRECTOS</h3>
                    <h4>Diseño y fabricación de taxímetros y dispositivos.</h4>
                    <img src="../../../assets/img/robot.png" width="50" alt="">
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>
<app-catalog></app-catalog>
<app-location></app-location>
<app-footer></app-footer>