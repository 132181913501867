<app-menu></app-menu>
<div class="container-fluid mt-5">
    <div class="col-12">
        <div class="row">
            <div class="col-6">
                <h3>
                    <img src="../../../assets/img/clientes.png" width="70"> Listado de Clientes:
                </h3>
            </div>
            <div class="col-4">
                <input style="color: blue; font-size: 25px;" type="text" class="form-control mt-2" [(ngModel)]="filterProducto" name="filterProducto" placeholder="Buscar por cédula" autocomplete="off">
            </div>
            <div class="col-2">
                <button class="btn btn-success btn-block mt-2" [routerLink]="['/nuevo']">Nuevo Cliente</button>
            </div>
        </div>
    </div>
    <hr>
    <div class="mt-2">
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Cédula</th>
                    <th scope="col">Interno</th>
                    <th scope="col">Placa</th>
                    <th scope="col">Teléfono</th>
                    <th scope="col">Direccion</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of clientes | filter:filterProducto">
                    <th>{{item.nombre | titlecase}}</th>
                    <td>{{item.cedula}}</td>
                    <td>{{item.interno}}</td>
                    <td>{{item.placa}}</td>
                    <td>{{item.telefono}}</td>
                    <td>{{item.direccion}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>