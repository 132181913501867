<div>
    <div class="container-fluid">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-10 text-center mt-5">
                    <p>&copy; 2021 Diseñado y desarrollado por
                        <a href="https://itssoluciones.com/" target="_blank">ITS Soluciones</a>
                        <p>
                </div>
                <div class="col-md-2 right">
                    <img src="../../../assets/img/logo.png" alt="" width="100">
                </div>
            </div>
        </div>
    </div>
</div>