<app-menu></app-menu>
<div class="container-fluid mt-5">
    <div class="col-5">
        <h3>
            <img src="../../../assets/img/tablet.png" width="70"> Control de jornada:
        </h3>
        <br>
        <label>Seleccione fecha:</label>
        <input type="date" class="form-control" (change)="verFacturas($event.target)">
    </div>
    <hr>
    <br>
    <div class="mt-2">
        <div class="mt-2">
            <div *ngIf="lista">
                <h4>Número de facturas: {{lista.length}} &nbsp;&nbsp; Ventas contado: ${{saldo | number}}&nbsp;&nbsp; Ventas crédito: ${{saldocredito | number}} &nbsp;&nbsp; Recaudo crédito: ${{pagocredito | number}}</h4>
            </div>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Cliente</th>
                        <th scope="col">Fecha Compra</th>
                        <th scope="col">Factura</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Placa</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of lista">
                        <th>{{item.cliente}}</th>
                        <td>{{item.fechaCompra | date}}</td>
                        <td>{{item.factura}}</td>
                        <td>{{item.estado}}</td>
                        <td>{{item.tipo}}</td>
                        <td>{{item.placa}}</td>
                        <td>${{item.valorTotal | number}}</td>
                        <td>
                            <button class="btn btn-success" (click)="detalleVenta(item)" data-toggle="modal" data-target="#factura" title="Detalle de Factura"><i class="fa fa-list"></i></button>
                          </td>
                    </tr>
                </tbody>
            </table>
        </div>
  </div>
</div>
<div class="modal fade" id="factura" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    <h4>Cliente: <b>{{cliente}}</b> <button class="btn btn-light ml-5" printSectionId="print-section" ngxPrint><img src="../../../assets/img/printing.png" width="30" alt=""></button></h4>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div style="width: 100%;">
                    <h5 class="sombra">Fecha: <b style="float: right;">{{fechaCompra | date: 'dd/MM/yyyy hh:mm a'}}</b></h5>
                    <h5 class="sombra">Cliente: <b style="float: right;">{{cliente}}</b></h5>
                    <h5 class="sombra">Identificación: <b style="float: right;">{{identificacion}}</b></h5>
                    <h5 *ngIf="temperatura" class="sombra">Temperatura: <b style="float: right;">{{temperatura}}°C</b></h5>
                    <h5 class="sombra">Placa del Vehículo: <b style="float: right;">{{placa | uppercase}}</b></h5>
                    <h5 class="sombra">Interno del Vehículo: <b style="float: right;">{{interno | uppercase}}</b></h5>
                    <h5 class="sombra">Teléfono: <b style="float: right;">{{telefono}}</b></h5>
                    <h5 class="sombra">Dirección: <b style="float: right;">{{direccion}}</b></h5>
                    <h5 class="sombra">Total: <b style="color: red;float: right;">${{valorTotal | number}}</b></h5>
                    <div *ngIf="tipo == 'Credito'">
                        <h5 class="sombra">Estado: <b style="color: rgb(11, 15, 223);float: right;">{{estado}}</b></h5>
                        <h5 *ngIf="estado == 'Pagado'" class="sombra">Saldo: <b style="color: red;float: right;">${{saldo | number}}</b></h5>
                    </div>
                    <hr>
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col">Producto</th>
                                <th scope="col">Cantidad</th>
                                <th scope="col">Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of productos">
                                <td>{{item.producto}}</td>
                                <td>{{item.cantidad}}</td>
                                <td>${{item.precio | number}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="width: 100%; display: none;" id="print-section">
    <div align='center'>
        <p><b style="font-size: 17px;">Servi-Taxímetros Edicar Microled</b> <br> NIT: 66826023-5 <br> Calle 33 No. 6 - 24 Pereira <br> Tel. (6) 3292397 <br>315 3227979</p>
    </div>
    <hr>
    <div>
        <table style="width: 100%;">
            <tbody>
                <tr>
                    <td>Fecha Facturación:</td>
                    <td><b>{{fechaCompra | date: 'dd/MM/yyyy hh:mm a'}}</b></td>
                </tr>
                <tr>
                    <td>Factura Venta:</td>
                    <td><b>No.{{factura}}</b></td>
                </tr>
                <tr>
                    <td>Placa:</td>
                    <td><b>{{placa}}</b></td>
                </tr>
                <tr>
                    <td>Interno:</td>
                    <td><b>{{interno}}</b></td>
                </tr>
                <tr>
                    <td>Cliente:</td>
                    <td><b>{{cliente}}</b></td>
                </tr>
                <tr>
                    <td>Identificacion:</td>
                    <td><b>C.C {{identificacion}}</b></td>
                </tr>
                <tr>
                    <td>Dirección:</td>
                    <td><b>{{direccion}}</b></td>
                </tr>
                <tr>
                    <td>Teléfono:</td>
                    <td><b>{{telefono}}</b></td>
                </tr>
                <tr>
                    <td>Temperatura:</td>
                    <td><b>{{temperatura}}°C</b></td>
                </tr>
                <div *ngIf="tipo == 'Credito'">
                    <tr>
                        <td>Estado:</td>
                        <td><b>{{estado | uppercase}}</b></td>
                    </tr>
                    <tr *ngIf="estado == 'Pagado'">
                        <td>Saldo:</td>
                        <td><b>${{saldo}}</b></td>
                    </tr>
                </div>
            </tbody>
        </table>
        <hr>
        <table style="width: 100%;border: 1px solid rgb(75, 74, 74);">
            <tbody>
                <tr>
                    <td><b>Producto</b></td>
                    <td><b>Cantidad</b></td>
                    <td><b>Precio</b></td>
                </tr>
                <tr *ngFor="let item of productos">
                    <td>{{item.producto}}</td>
                    <td>{{item.cantidad}}</td>
                    <td>{{item.precio}}</td>
                </tr>
            </tbody>
        </table>
        <div style="width: 100%;">
            <h3 style="float: right;"><b>TOTAL: ${{valorTotal}}</b></h3>
        </div>
        <br>
        <br>
        <br>
        <br>
        <div align='center'>
            <p style="font-size: 8px;">Esta Factura de Venta se asimila en todos sus efectos legales a una letra de cambio según Art 779 del códifo de comercio. Modificado Art 5 ley 1231 de 2008. De acuerdo con la ley 1943 del 28 de Dic 2018.</p>
        </div>
    </div>
</div>
