import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ProductosService } from '../../services/productos.service';

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.scss']
})
export class VentasComponent implements OnInit {
  credito = true;
  filterProducto = '';
  ventas: any;
  lista: any;
  cliente: string;
  telefono: string;
  direccion: string;
  identificacion: string;
  factura: number;
  idFact: string;
  placa: string;
  valorTotal: number;
  estado: string;
  saldo = 0;
  productos: any;
  fechaCompra: number;
  interno: string;
  tipo: string;
  temperatura: string;
  jornada: any;
  saldoCaja: number;
  factInicio: number;
  factFin: number;
  factSeleccionada: any;
  newNumberFact: number;
  fechaJornada: number;
  fecpagocred:any;
  codFac: any;

  constructor(private prodSvc: ProductosService) { }

  ngOnInit(): void {
    this.prodSvc.jornadaEstado().then(std => {
      this.jornada = std;
      this.saldoCaja = this.jornada[0].totalVentas;
      this.factInicio = this.jornada[0].facturaInicio;
      this.factFin = this.jornada[0].facturaFin;
      this.fechaJornada = this.jornada[0].fecha;
      console.log(this.jornada);
    });
    this.codJornada();
  }

  codJornada(){
    let dia = new Date().getDate();
    let mes = new Date().getMonth() + 1;
    let anio = new Date().getFullYear();
    let day = dia.toString();
    let month = mes.toString();
    if (dia < 10) {
      day = '0'+ day;
    }
    if (mes < 10) {
      month = '0'+ month;
    }
    this.codFac = anio+'-'+month+'-'+day;
    this.prodSvc.cargarVentasJornadaServitax(this.codFac)
    .subscribe(res => {
      this.ventas = res;
      this.lista = res;
      // console.log(this.ventas);
    });
  }

  modificarConsecutivoLista(){
    for (const key in this.ventas){
      let num = 1100000 + this.ventas[key].factura;
      this.prodSvc.editarConsecutivoFactura(this.ventas[key].id, num);
    }
  }

  searchId(cedula: string){
    this.prodSvc.cargarVentasPorCedula(cedula)
                .subscribe(res => {
                  this.ventas = res;
                });
  }

  searchPlc(placa: string){
    this.prodSvc.cargarVentasPorPlaca(placa)
                .subscribe(res => {
                  this.ventas = res;
                });
  }
  searchInterno(interno: string){
    this.prodSvc.cargarVentasPorInterno(interno)
                .subscribe(res => {
                  this.ventas = res;
                });
  }

  detalleVenta(venta: any){
    // console.log(venta);
    this.idFact = venta.id;
    this.factura = venta.factura;
    this.cliente = venta.cliente;
    this.identificacion = venta.cedula;
    this.telefono = venta.telefono;
    this.direccion = venta.direccion;
    this.productos = venta.productos;
    this.fechaCompra = venta.fechaCompra;
    this.placa = venta.placa;
    this.estado = venta.estado;
    this.valorTotal = venta.valorTotal;
    this.interno = venta.interno;
    this.temperatura = venta.temperatura;
    this.tipo = venta.tipo;
  }

  verListaCompleta(){
    this.ventas = [];
    this.ventas = this.lista;
  }

  creditosPendientes(){
    this.credito = false;
    this.ventas = [];
    this.prodSvc.cargarCreditosPendientes()
                .subscribe(res => {
                  this.ventas = res;
                });
  }

  pagarCreditoPendiente(factura: any){
    let dia = new Date().getDate();
    let mes = new Date().getMonth() + 1;
    let anio = new Date().getFullYear();
    let day = dia.toString();
    let month = mes.toString();
    if (dia < 10) {
      day = '0'+ day;
    }
    if (mes < 10) {
      month = '0'+ month;
    }
    this.fecpagocred = anio+'-'+month+'-'+day;
    const newSald = factura.valorTotal + this.saldoCaja;
    Swal.fire({
      title: 'Está seguro?',
      text: 'Se va a pagar la factura seleccionada!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Pagar Factura!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prodSvc.pagarCreditoPendiente(factura.id, this.fecpagocred).then(() => {
          Swal.fire(
            'Pagada!',
            'La factura ha sido pagada correctamente.',
            'success'
          );
        });
      }
    });
  }

  anularVenta(venta: any){
    console.log(venta);
    let total = this.saldoCaja - venta.valorTotal

    Swal.fire({
      title: 'está seguro?',
      text: "Se va a anular esta factura!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, anular!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prodSvc.anularFactura(venta.id, venta.factura, total).then(() => {
          Swal.fire(
            'Anulada!',
            'Factura de venta anulada correctamente.',
            'success'
          );
        });
      }
    })
  }

  factSel(factura: any){
    this.factSeleccionada = factura;
    console.log(this.factSeleccionada);
  }
  editFact(){
    this.prodSvc.editarConsecutivoFactura(this.factSeleccionada.id, this.newNumberFact);
  }
}

// jhoan lopez mejia 3443700 ext 1812. jhojan.lopez@unad.edu.co  -- SXG405
