export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCfZfII-dsmvzV2Zha9tqzgCwl-qndnCPU",
  authDomain: "microedicar.firebaseapp.com",
  databaseURL: "https://microedicar.firebaseio.com",
  projectId: "microedicar",
  storageBucket: "microedicar.appspot.com",
  messagingSenderId: "837633928759",
  appId: "1:837633928759:web:b66f667f9cde19705dd9b2",
  measurementId: "G-VVJJ6WX63S"
  }
};
