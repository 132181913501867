import { Component, OnInit } from '@angular/core';
import { ProductosService } from '../../services/productos.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CarritoI } from '../../shared/pedido.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss']
})
export class ProductoComponent implements OnInit {
  idProd: string;
  nombre: string;
  descripcion: string;
  imagen: string;
  precio: number;
  soporte = false;
  cant = 1;
  compra = [];

  carshop: any = {
    nombre: '',
    imagen: '',
    cantidad: 0,
    precio: 0,
    total: 0
  };

  constructor(private prodSvc: ProductosService,
              private route: ActivatedRoute,
              private router: Router) {
    this.loadStorage();
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id === 'G0VxPuTzLZzLnyOQO7rF') {
      this.soporte = true;
    }
    if (this.prodSvc.producto) {
      this.idProd = this.prodSvc.producto[1];
      this.nombre = this.prodSvc.producto[0].nombre;
      this.descripcion = this.prodSvc.producto[0].descripcion;
      this.imagen = this.prodSvc.producto[0].imagen;
      this.precio = this.prodSvc.producto[0].precio;
    } else {
       this.router.navigate(['/home']);
    }
  }

  cantidadProducto(operacion: string){
    if (operacion === 'restar') {
      this.cant--;
      if (this.cant < 1) {
        this.cant = 1;
      }
    }
    if (operacion === 'sumar') {
      this.cant++;
      if (this.cant > 20) {
        this.cant = 20;
      }
    }
  }

  addCarShop(){
    const total = this.precio * this.cant;
    this.carshop.nombre = this.nombre;
    this.carshop.imagen = this.imagen;
    this.carshop.cant = this.cant;
    this.carshop.precio = this.precio;
    this.carshop.total = total;
    this.compra.push(this.carshop);
    localStorage.setItem('data', JSON.stringify(this.compra));
    Swal.fire({
      title: 'Se han agregado' + ' ' + this.cant + ' unidades de' + ' ' + this.nombre + ' al carrito de compras',
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    });
    this.router.navigate(['compras']);
  }

  loadStorage(){
    if (localStorage.getItem('data')) {
      this.compra = JSON.parse( localStorage.getItem('data'));
    }
  }
}
