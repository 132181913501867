<div class="adm">
    <div class="container">
        <div class="col-lg-12">
            <div class="sesion">
                <h3>Inicio Sesión Administrador</h3>
                <div class="card" style="width: 20rem;">
                    <div class="card-body">
                        <form [formGroup]="loginFormAdmin" (ngSubmit)="onLoginAdmin()">
                            <div class="form-group">
                                <label for="">E-mail Administrador</label>
                                <input type="text" class="form-control" formControlName="email" placeholder="@Correo">
                            </div>
                            <div class="form-group">
                                <label for="">Password</label>
                                <input type="password" class="form-control" formControlName="password" placeholder="Contraseña">
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn btn-dark btn-block">Continuar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>