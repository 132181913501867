<app-menu></app-menu>
<div class="container mt-5">
    <div class="col-12">
        <div class="row">
            <div class="col-6">
                <h2>Consecutivo de Facturación:</h2>
                <hr>
                <label>Ingrese el número de la factua de inicio:</label><br>
                <input (change)="consecutivo($event.target.value)" type="number" class="cnsctvo" min="0" placeholder="Ingrese número">
                <br>
                <button class="btn btn-primary btn-lg mt-2" (click)="guardaConsecutivo()">Guardar Número de Factura</button>
            </div>
            <div class="col-6">
                <h2>Precio Cambio Tarifa:</h2>
                <hr>
                <label>Ingrese el el valor del cambio de tarifa:</label><br>
                <input (change)="cambioTarifaPrecio($event.target.value)" type="number" class="cnsctvo" min="0" placeholder="Ingrese precio">
                <br>
                <button class="btn btn-primary btn-lg mt-2" (click)="precioTarifa()">Actualizar Precio</button>
            </div>
        </div>
    </div>
</div>