import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { finalize, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  productos: any;
  producto: any;
  carro: any;
  solicitudes: number;
  solicitud = [];
  constructor(private db: AngularFirestore) { }
  public factura: any;

  cargarProductos(){
    return new Promise((resolve, reject) => {
      this.db.collection('productos')
              .get().forEach(res => {
                if (res.empty){
                  reject('error');
                }else{
                  const datapro = [];
                  res.forEach(res1 => {
                    datapro.push([res1.data(), res1.id]);
                  });
                  resolve(datapro);
                }
              }).catch();
    });
  }

  CargarElementos(id: string){
    return new Promise((resolve, reject) => {
      this.db.collection('productos/', ref => ref
             .where('categoria', '==', id)).get().forEach(res => {
        if (res.empty){
          reject('error');
        }else{
          const datapro = [];
          res.forEach(res1 => {
            datapro.push([res1.data(), res1.id]);
          });
          resolve(datapro);
        }
      }).catch();
    });
  }

  crearCompra(compra: any){
    this.solicitud.push(compra);
    this.guardaStorage();
  }

  guardaStorage(){
    localStorage.setItem('data', JSON.stringify(this.solicitud));
  }

  updateStorage(compra: any){
    localStorage.setItem('data', JSON.stringify(compra));
  }

  cargarSolicitudCompra(solicitud: any){
    return this.db.collection('ventas').add(solicitud);
  }

  invoice(){
    return this.db.collection('consecutivo').valueChanges();
  }

  gestionCambiosTarifa(estado: boolean){
    return this.db.collection('consecutivo').doc('ewM7hnWnO7p7hhIgC49o').update({
      activado: estado
    });
  }

  facturacion(factura: any){
    return this.db.collection('facturacion').add(factura);
  }

  actualizarConsecutivo(newFact: number){
    return this.db.collection('consecutivo').doc('ollUrZelgcbDG2pokTgM').update({factura: newFact});
  }

  actualizarCambioTarifa(newPrecio: number){
    return this.db.collection('consecutivo').doc('ollUrZelgcbDG2pokTgM').update({cambioTarifa: newPrecio});
  }
  cargarVentas(){
    return this.db.collection('facturacion', ref => ref
                  .orderBy('fechaCompra', 'desc').limit(2000))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                     actions.map(resp => {
                     const data = resp.payload.doc.data() as any;
                     const id = resp.payload.doc.id;
                     return {id, ...data};
                     }))
                    );
  }

  cargarVentasJornada(factIni: number, factFin: number){

   return this.db.collection('facturacion', ref => ref
                  .where('factura','>=', factIni)
                  .where('factura','<=', factFin)
                  .orderBy('factura', 'desc'))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                     actions.map(resp => {
                     const data = resp.payload.doc.data() as any;
                     const id = resp.payload.doc.id;
                     return {id, ...data};
                     }))
                    );
  }
  cargarVentasJornadaHistorial(factIni: number, factFin: number){

    return this.db.collection('facturacion', ref => ref
                   .where('factura','>=', factIni)
                   .where('factura','<=', factFin)
                   .orderBy('factura', 'desc'))
                   .snapshotChanges()
                   .pipe(
                     map(actions =>
                      actions.map(resp => {
                      const data = resp.payload.doc.data() as any;
                      const id = resp.payload.doc.id;
                      return {id, ...data};
                      }))
                     );
   }


  editarConsecutivoFactura(idFac: string, factNum: number){
    return this.db.collection('facturacion').doc(idFac).update({factura: factNum});
  }

  anularFactura(idFac: string, factura: number, total: number){
    return this.db.collection('facturacion').doc(idFac).update({
      valorTotal: 0,
      estado: 'Anulado'
    }).then(() => {
      this.actualizarSaldoVentas(factura, total);
    })
  }

  cargarVentasPorCedula(cedula: string){
    return this.db.collection('facturacion', ref => ref
                  .where('cedula', '==', cedula))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                     actions.map(resp => {
                     const data = resp.payload.doc.data() as any;
                     const id = resp.payload.doc.id;
                     return {id, ...data};
                     }))
                    );
  }

  cargarVentasPorPlaca(placa: string){
    return this.db.collection('facturacion', ref => ref
                  .where('placa', '==', placa))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                     actions.map(resp => {
                     const data = resp.payload.doc.data() as any;
                     const id = resp.payload.doc.id;
                     return {id, ...data};
                     }))
                    );
  }

  cargarVentasPorInterno(lateral: string){
    return this.db.collection('facturacion', ref => ref
                  .where('interno', '==', lateral))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                     actions.map(resp => {
                     const data = resp.payload.doc.data() as any;
                     const id = resp.payload.doc.id;
                     return {id, ...data};
                     }))
                    );
  }

  cargarClientes(){
    return this.db.collection('clientes', ref => ref
                  .orderBy('nombre', 'asc').limit(50))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                     actions.map(resp => {
                     const data = resp.payload.doc.data() as any;
                     const id = resp.payload.doc.id;
                     return {id, ...data};
                     }))
                    );
  }


  guardarCliente(cliente: any){
    return this.db.collection('clientes').add(cliente);
  }
  actualizarCliente(idClient: string, cliente: any){
    return this.db.collection('clientes').doc(idClient).update(cliente);
  }

  buscarVehiculoPorInterno(inter: string){
    return this.db.collection('clientes', ref => ref
                  .where('placa', '==', inter))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                     actions.map(resp => {
                     const data = resp.payload.doc.data() as any;
                     const id = resp.payload.doc.id;
                     return {id, ...data};
                     }))
                    );
  }

  jornadaEstado(){
    return new Promise((resolve, reject) => {
      this.db.collection('jornada')
              .get().forEach(res => {
                if (res.empty){
                  reject('error');
                }else{
                  const datapro = [];
                  res.forEach(res1 => {
                    datapro.push(res1.data());
                  });
                  resolve(datapro);
                }
              }).catch();
    });
    // return this.db.collection('jornada').valueChanges();
  }

  iniciarJornadaLaboral(idJornada: string, factura: number){
    return this.db.collection('jornada').doc(idJornada).update({
      estado: 'iniciado',
      fecha: new Date().getTime(),
      facturaInicio: factura,
      facturaFin: 0,
      totalVentas: 0
    });
  }

  actualizarSaldoVentas(factura: number, newTotal: number){
    return this.db.collection('jornada').doc('Wk8ykPwdBbSLWnbtUOhL').update({
      facturaFin: factura,
      totalVentas: newTotal
    });
  }
  nuevoSaldoVentas(newTotal: number){
    return this.db.collection('jornada').doc('Wk8ykPwdBbSLWnbtUOhL').update({
      totalVentas: newTotal
    });
  }

  FinalizarJornadaLaboral(){
    return this.db.collection('jornada').doc('Wk8ykPwdBbSLWnbtUOhL').update({
      estado: 'cerrada',
      fecha: new Date().getTime()
    });
  }

  jornadaHistorial(jornada: any){
    return this.db.collection('historicoJornada').add(jornada);
  }

  cargarPedidos(){
    return this.db.collection('ventas', ref => ref
                  .orderBy('fecha', 'desc'))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                     actions.map(resp => {
                     const data = resp.payload.doc.data() as any;
                     const id = resp.payload.doc.id;
                     return {id, ...data};
                     }))
                    );
  }

  filtrarPedidos(dato: string){
    return this.db.collection('ventas', ref => ref
                  .where('estado', '==', dato))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                     actions.map(resp => {
                     const data = resp.payload.doc.data() as any;
                     const id = resp.payload.doc.id;
                     return {id, ...data};
                     }))
                    );
  }


  aceptarPedido(idPedido: string){
    return this.db.collection('ventas').doc(idPedido).update({estado: 'En Envio'});
  }

  cargarListaJornada(){
    return this.db.collection('historicoJornada', ref => ref
                  .orderBy('fecha', 'desc'))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                     actions.map(resp => {
                     const data = resp.payload.doc.data() as any;
                     const id = resp.payload.doc.id;
                     return {id, ...data};
                     }))
                    );
  }

  verificarPlacaCliente(placa: string){
    return this.db.collection('clientes', ref => ref
                  .where('placa', '==', placa))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                     actions.map(resp => {
                     const data = resp.payload.doc.data() as any;
                     const id = resp.payload.doc.id;
                     return {id, ...data};
                     }))
                    );
  }

  agregarCliente(cliente: any){
    return this.db.collection('clientes').add(cliente);
  }

  cargarCreditosPendientes(){
    return this.db.collection('facturacion', ref => ref
                  .where('tipo', '==', 'Credito')
                  .where('estado', '==', 'Pendiente'))
                  .snapshotChanges()
                  .pipe(
                    map(actions =>
                     actions.map(resp => {
                     const data = resp.payload.doc.data() as any;
                     const id = resp.payload.doc.id;
                     return {id, ...data};
                     }))
                    );
  }

  pagarCreditoPendiente(idFactura: any, fecpagocred: any){
    return this.db.collection('facturacion').doc(idFactura).update({
      estado: 'Pagado',
      fecpagocredito: fecpagocred
    });
  }

  cargarVentasJornadaServitax(codfac: any){
    return this.db.collection('facturacion', ref => ref
                   .where('codFactura','==', codfac))
                   .snapshotChanges()
                   .pipe(
                     map(actions =>
                      actions.map(resp => {
                      const data = resp.payload.doc.data() as any;
                      const id = resp.payload.doc.id;
                      return {id, ...data};
                      }))
                     );
   }

   cargarVentascreditoPagas(fecpago: any){
    return this.db.collection('facturacion', ref => ref
                   .where('fecpagocredito','==', fecpago))
                   .snapshotChanges()
                   .pipe(
                     map(actions =>
                      actions.map(resp => {
                      const data = resp.payload.doc.data() as any;
                      const id = resp.payload.doc.id;
                      return {id, ...data};
                      }))
                     );
   }

}
