import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductosService } from 'src/app/services/productos.service';

@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.scss']
})
export class FacturasComponent implements OnInit {

  ventas: any;
  lista = [];

  idFact: any;
  factura: any;
  cliente: any;
  identificacion: any;
  telefono: any;
  direccion: any;
  productos: any;
  fechaCompra: any;
  placa: any;
  estado: any;
  valorTotal: any;
  interno: any;
  temperatura: any;
  tipo: any;
  saldo = 0;
  fecha: any;
  constructor(private route: ActivatedRoute,
              private prodSvc: ProductosService) { }

  ngOnInit(): void {
    const inicio = Number(this.route.snapshot.paramMap.get('inicio'));
    const fin = Number(this.route.snapshot.paramMap.get('fin'));
    this.fecha = Number(this.route.snapshot.paramMap.get('fecha'));
    this.prodSvc.cargarVentasJornadaHistorial(inicio, fin)
                .subscribe(res => {
                  this.ventas = res;
                  const milisegundos = 24 * 60 * 60 * 1000;
                  for (const i in this.ventas) {
                    const milisegundosTranscurridos = Math.abs(this.ventas[i].fechaCompra - this.fecha);
                    const dias = Math.round(milisegundosTranscurridos / milisegundos);
                    if (dias < 5) {
                      this.lista.push(this.ventas[i]);
                    }
                  }
                })
  }

  detalleVenta(venta: any){
    this.idFact = venta.id;
    this.factura = venta.factura;
    this.cliente = venta.cliente;
    this.identificacion = venta.cedula;
    this.telefono = venta.telefono;
    this.direccion = venta.direccion;
    this.productos = venta.productos;
    this.fechaCompra = venta.fechaCompra;
    this.placa = venta.placa;
    this.estado = venta.estado;
    this.valorTotal = venta.valorTotal;
    this.interno = venta.interno;
    this.temperatura = venta.temperatura;
    this.tipo = venta.tipo;
  }
}
