import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductosService } from '../../services/productos.service';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
export class ProductosComponent implements OnInit {
  titulo: string;
  lista: any;
  productos = [];
  idCat: string;

  constructor(private route: ActivatedRoute,
              private prodSvc: ProductosService,
              private router: Router) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.prodSvc.cargarProductos().then(res => {
      this.lista = res;
      if (id === 'taximetros') {
        this.idCat = id;
        this.titulo = 'Taxímetros:';
      }
      if (id === 'velocimetros') {
        this.idCat = id;
        this.titulo = 'Controles de Velocidad:';
      }
      if (id === 'gps') {
        this.idCat = 'velocimetros';
        this.titulo = 'Controles de Velocidad GPS:';
      }
      if (id === 'soporte') {
        this.idCat = 'soporte';
        this.titulo = 'Soporte técnico:';
      }
      for (const key in this.lista){
        if (this.lista[key][0].categoria === this.idCat) {
          this.productos.push(this.lista[key]);
        }
      }
      /* console.log(this.idCat);
      console.log('Productos: ', this.lista); */
    });
  }

  detalle(producto: any){
    this.prodSvc.producto = producto;
    this.router.navigate([`/producto/${producto[1]}`]);
  }
}
