import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductosService } from '../../services/productos.service';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent implements OnInit {

  lista: any;

  idFact: any;
  factura: any;
  cliente: any;
  identificacion: any;
  telefono: any;
  direccion: any;
  productos: any;
  fechaCompra: any;
  placa: any;
  estado: any;
  valorTotal: any;
  interno: any;
  temperatura: any;
  tipo: any;
  saldo = 0;
  saldocredito = 0;
  pagocredito = 0;
  fecha: any;

  codfac: any;

  constructor(private prodSvc: ProductosService,
              private router: Router) { }

  ngOnInit(): void {

  }

  verFacturas(jornada: any){
    this.saldo = 0;
    this.saldocredito = 0;
    this.pagocredito = 0;
    this.lista = undefined;
    let cod = jornada.value;
    this.codfac = cod.toString();
   this.prodSvc.cargarVentasJornadaServitax(this.codfac)
   .subscribe(res => {
     this.lista = res;

     for (const key in this.lista) {
      if (this.lista[key].tipo === 'Contado') {
        this.saldo = this.saldo + this.lista[key].valorTotal;
      }
      if (this.lista[key].tipo === 'Credito') {
        this.saldocredito = this.saldocredito + this.lista[key].valorTotal;
      }
     }
   });
   this.prodSvc.cargarVentascreditoPagas(this.codfac)
               .subscribe(fac => {
                let list:any = fac;
                for (const key in list) {
                    this.pagocredito = this.pagocredito + list[key].valorTotal;
                 }
               });
  }

   detalleVenta(venta: any){
    this.idFact = venta.id;
    this.factura = venta.factura;
    this.cliente = venta.cliente;
    this.identificacion = venta.cedula;
    this.telefono = venta.telefono;
    this.direccion = venta.direccion;
    this.productos = venta.productos;
    this.fechaCompra = venta.fechaCompra;
    this.placa = venta.placa;
    this.estado = venta.estado;
    this.valorTotal = venta.valorTotal;
    this.interno = venta.interno;
    this.temperatura = venta.temperatura;
    this.tipo = venta.tipo;
  }

}
