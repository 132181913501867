import { Component, OnInit } from '@angular/core';
import { ProductosService } from 'src/app/services/productos.service';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {

  filterProducto = '';
  clientes: any;
  constructor(private prodSvc: ProductosService) { }

  ngOnInit(): void {
    this.prodSvc.cargarClientes()
                .subscribe(res => {
                  this.clientes = res;
                  // console.log(this.ventas);
                });
  }
}
