import { Component, OnInit } from '@angular/core';
import { ProductosService } from '../../services/productos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-administracion',
  templateUrl: './administracion.component.html',
  styleUrls: ['./administracion.component.scss']
})
export class AdministracionComponent implements OnInit {
  docJornada = 'Wk8ykPwdBbSLWnbtUOhL';
  factr = false;
  consecutivo: any;
  cambioTarifa: boolean;
  cmtarifa: number;
  cambios = 1;
  factura: number;
  fecha = new Date();
  nombreCliente: string;
  identificacion: string;
  phone: string;
  dir: string;
  placaVehiculo: string;
  internoVehiculo: string;
  item: string;
  cntItem = 1;
  costoItem: number;
  liquidacion = 0;
  prodList = {
    producto: '',
    cantidad: 0,
    precio: 0
  };
  facturador = [];
  datosFactura = {
    codFactura: '',
    factura: 0,
    cliente: '',
    cedula: '',
    telefono: '',
    direccion: '',
    temperatura: '',
    placa: '',
    interno: '',
    productos: [],
    valorTotal: 0,
    fechaCompra: 0,
    tipo: '',
    estado: '',
  };

  dataCliente = {
    nombre: '',
    cedula: '',
    telefono: '',
    direccion: '',
    placa: '',
    interno: '',
  };
  idClient: string;
  formuFact = false;
  nuevoCliente = false;
  editarDatosCliente = false;
  intBusca: string;
  encontrado: any;
  jornada: any;
  dia: boolean;
  sumatoria = 0;
  saldo = 0;
  tempt: string;
  facTipo = false;
  tipoVenta: string;

  codFac: any;
  ventas: any;

  constructor(private prodSvc: ProductosService) { }

  ngOnInit(): void {
    this.codJornada();
    this.numFactura();
    this.prodSvc.jornadaEstado().then(std => {
      this.jornada = std;
      // console.log();
      if (this.jornada[0].estado === 'cerrada') {
        this.dia = false;
      }
      if (this.jornada[0].estado === 'iniciado') {
        this.dia = true;
      }
    });
  }

  codJornada(){
    let dia = new Date().getDate();
    let mes = new Date().getMonth() + 1;
    let anio = new Date().getFullYear();
    let day = dia.toString();
    let month = mes.toString();
    if (dia < 10) {
      day = '0'+ day;
    }
    if (mes < 10) {
      month = '0'+ month;
    }
    this.codFac = anio+'-'+month+'-'+day;
    this.prodSvc.cargarVentasJornadaServitax(this.codFac)
    .subscribe(res => {
      this.ventas = res;
      console.log(this.ventas);
      for (const key in this.ventas) {
       if (this.ventas[key].tipo === 'Contado') {
        this.saldo = this.saldo + this.ventas[key].valorTotal;
       }
      }
    });
  }

  numFactura(){
    this.prodSvc.invoice().subscribe(res => {
      this.consecutivo = res;
      this.factura = this.consecutivo[0].factura + 1;
      this.cambioTarifa = this.consecutivo[0].activado;
      this.cmtarifa = this.consecutivo[0].cambioTarifa;
      // console.log('datos: ', this.factura, '-',  this.cambioTarifa, '-', this.cmtarifa);
    });
  }

  iniciarJornada(){
    Swal.fire({
      title: 'Está Seguro?',
      text: 'Se va a iniciar sesión',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Iniciar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prodSvc.iniciarJornadaLaboral(this.docJornada, this.factura).then(() => {
            Swal.fire(
            'Iniciado!',
            'La sesión ha comenzado.',
            'success'
          );
            window.location.reload();
        });
      }
    });
  }

  nombre(nombre){
    this.nombreCliente = nombre;
  }
  cedula(cc){
    this.identificacion = cc;
  }
  telefono(tl){
    this.phone = tl;
  }
  direccion(dr){
    this.dir = dr;
  }
  placa(plc){
    this.placaVehiculo = plc;
  }

  cantCambiosTarifa(cnt){
    this.cambios = cnt;
  }
  interno(intr){
    this.internoVehiculo = intr;
  }

  cambiotf(){
    this.prodList = {
      producto: '',
      cantidad: 0,
      precio: 0
    };
    const total = this.cambios * this.cmtarifa;
    this.prodList.producto = 'Cambio Tarifa';
    this.prodList.cantidad = this.cambios;
    this.prodList.precio = this.cmtarifa;
    this.facturador.push(this.prodList);
    this.liquidacion = total + this.liquidacion;
  }

  itemFactura(itm){
    this.item = itm;
  }

  cantidadItem(cantI){
    this.cntItem = cantI;
  }

  costoItemSeleccionado(cstItm){
    this.costoItem = cstItm;
    // console.log(this.costoItem);
  }

  agregarItemFactura(){
    if (this.costoItem > 0) {
      this.prodList = {
        producto: '',
        cantidad: 0,
        precio: 0
      };
      const total = this.cntItem * this.costoItem;
      this.prodList.producto = this.item;
      this.prodList.cantidad = this.cntItem;
      this.prodList.precio = this.costoItem;
      this.facturador.push(this.prodList);
      this.liquidacion = total + this.liquidacion;
      // console.log(this.facturador);
    } else {
      Swal.fire(
        'Error',
        'Debe ingresar el producto o servicio y su valor',
        'question'
      );
    }
  }

  borrarItem(item, costo, cant){
    this.facturador.splice(item, 1);
    const valor = costo * cant;
    this.liquidacion = this.liquidacion - valor;
  }
  desactivarCambio(){
    Swal.fire({
      title: 'Está Seguro?',
      text: 'Va a desactivar el cambio de tarifa!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, desactivar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prodSvc.gestionCambiosTarifa(false);
        Swal.fire(
          'Desactivado',
          'El cambio de tarifa esta desactivado',
          'success'
        );
      }
    });
  }

  activarCambio(){
    Swal.fire({
      title: 'Está Seguro?',
      text: 'Va a activar el cambio de tarifa!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Activar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prodSvc.gestionCambiosTarifa(true);
        Swal.fire(
          'ACTIVADO',
          'El cambio de tarifa esta activado',
          'success'
        );
      }
    });
  }

  tipoFactura(tipoFactura: string){
    if (this.nombreCliente === undefined) {
      Swal.fire('Por favor ingrese en nombre del cliente para continuar');
      return;
    }
    if (this.identificacion === undefined) {
      Swal.fire('Por favor ingrese la cédula del cliente para continuar');
      return;
    }
    if (this.phone === undefined) {
      Swal.fire('Por favor ingrese el teléfono del cliente para continuar');
      return;
    }
    if (this.dir === undefined) {
      Swal.fire('Por favor ingrese la dirección del cliente para continuar');
      return;
    }
    if (this.placaVehiculo === undefined) {
      Swal.fire('Por favor ingrese la placa del vehículo para continuar');
      return;
    }
    if (this.internoVehiculo === undefined) {
      Swal.fire('Por favor ingrese el interno del vehículo para continuar');
      return;
    }
    if (this.nombreCliente && this.identificacion && this.phone && this.dir && this.placaVehiculo && this.internoVehiculo) {
      this.facTipo = true;
      this.tipoVenta = tipoFactura;
    }
  }

  facturacion(){
    if (this.liquidacion > 0 ) {
      if (this.placaVehiculo === undefined || this.identificacion === undefined) {
        Swal.fire(
          'Sin Cédula o Placa',
          'Debe ingresar el número de cédula y la placa del vehículo para facturar',
          'error'
        );
        return;
        } else {
          this.factr = true;
          // sumando ventas
          if (this.tipoVenta === 'Contado') {
            this.sumatoria = this.saldo + this.liquidacion;
            this.datosFactura.estado = 'Pagado';
          }
          if (this.tipoVenta === 'Credito') {
            this.sumatoria = this.saldo + 0;
            this.datosFactura.estado = 'Pendiente';
          }
          // factura
          if (!this.tempt) {
            this.tempt = '0';
          }
          this.datosFactura.codFactura = this.codFac;
          this.datosFactura.factura = this.factura;
          this.datosFactura.cliente = this.nombreCliente;
          this.datosFactura.cedula = this.identificacion;
          this.datosFactura.telefono = this.phone;
          this.datosFactura.direccion = this.dir;
          this.datosFactura.tipo = this.tipoVenta;
          this.datosFactura.temperatura = this.tempt;
          this.datosFactura.placa = this.placaVehiculo;
          this.datosFactura.interno = this.internoVehiculo;
          this.datosFactura.productos = this.facturador;
          this.datosFactura.valorTotal = this.liquidacion;
          this.datosFactura.fechaCompra = new Date().getTime();

          // cliente
          this.dataCliente.nombre = this.nombreCliente;
          this.dataCliente.cedula = this.identificacion;
          this.dataCliente.telefono = this.phone;
          this.dataCliente.direccion = this.dir;
          this.dataCliente.placa = this.placaVehiculo;
          this.dataCliente.interno = this.internoVehiculo;

          // console.log(this.datosFactura);

          // console.log(this.nuevoCliente, this.editarDatosCliente);

          if ((this.nuevoCliente) && (!this.editarDatosCliente)) {
            this.prodSvc.guardarCliente(this.dataCliente);
          }
          if ((!this.nuevoCliente) && (this.editarDatosCliente)) {
            this.prodSvc.actualizarCliente(this.idClient, this.dataCliente);
          }
        }
    } else {
      Swal.fire(
        'Factura sin productos o servicios',
        'Debe ingresar productos y/o servicios para facturar',
        'error'
      );
    }
  }

  buscaInterno(event){
    this.intBusca = event;
  }

  buscarVehiculo(){
    this.prodSvc.buscarVehiculoPorInterno(this.intBusca)
                .subscribe(res => {
                  this.encontrado = res;
                  if (this.encontrado.length === 0) {
                    Swal.fire(
                      'Cliente no encontrado',
                      'Ingrese los datos del cliente',
                      'error'
                    );
                    this.formuFact = true;
                    this.nuevoCliente = true;
                    this.editarDatosCliente = false;
                  } else {
                     // console.log(this.encontrado);
                    this.editarDatosCliente = true;
                    this.idClient = this.encontrado[0].id;
                    this.nombreCliente = this.encontrado[0].nombre;
                    this.identificacion = this.encontrado[0].cedula;
                    this.phone = this.encontrado[0].telefono;
                    this.dir = this.encontrado[0].direccion;
                    this.placaVehiculo = this.encontrado[0].placa;
                    this.internoVehiculo = this.encontrado[0].interno;
                  }
                });
  }

  editarCliente(){
    Swal.fire({
      title: 'Está Seguro?',
      text: 'Va a activar la edición de datos del cliente!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Activar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.formuFact = true;
        this.nuevoCliente = false;
        this.editarDatosCliente = true;
        Swal.fire(
          'ACTIVADO',
          'Ya puede actualizar los datos del cliente',
          'success'
        );
      }
    });
  }

  temperatura(temp: string){
    this.tempt = temp;
    // console.log(this.tempt);
  }

  imprimirFactura(){
    this.prodSvc.facturacion(this.datosFactura)
                 .then(() => {
                   Swal.fire(
                     'Facturado',
                     'Factura creada correctamente',
                     'success'
                   );
                   this.prodSvc.actualizarConsecutivo(this.factura)
                               .then(() => {
                                 window.location.reload();
                               });
                 });
   }
}
