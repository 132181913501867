<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" [routerLink]="['/home']">
        <img src="../../../assets/img/logo.png" width="70" alt="" loading="lazy">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/home']"><i class="fa fa-home" aria-hidden="true"></i>  Inicio <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#catalogo"><i class="fa fa-car" aria-hidden="true"></i> Servicios</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#contacto"><i class="fa fa-phone" aria-hidden="true"></i> Contacto</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/admin']"><i class="fa fa-user" aria-hidden="true"></i> Usuario</a>
            </li>
        </ul>
        <div class="text-right">
            <button class="carsec" title="Carrito de compras" (click)="openCar()">                
                <i class="fa fa-shopping-cart fa-2x"></i>
                <span class="badge badge-primary intermitente">{{cantidad$}}</span>  
            </button>
        </div>
    </div>
</nav>