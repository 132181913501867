import { Component, OnInit } from '@angular/core';
import { ProductosService } from '../../services/productos.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private prodSvc: ProductosService,
              private route: Router) {
    this.loadStorage();
   }
  lista: any;
  cantidad$: Observable<any>;
  ngOnInit(): void {
  }

  openCar(){
    this.route.navigate([`/compras`]);
  }

  loadStorage(){
    if (localStorage.getItem('data')) {
      this.lista = JSON.parse( localStorage.getItem('data'));
      this.cantidad$ = this.lista.length;
      console.log(this.cantidad$);
    }
  }

}
