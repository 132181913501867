<div class="container-fluid mt-5 mb-5">
    <div class="row">
        <div class="col-md-12 mt-5" id="catalogo">
            <h2><b>CATEGORÍAS</b> | Microtaxímetros Edicar Pereira</h2>
            <hr>
        </div>
        <div class="col-lg-12 mt-4">
            <div class="row row-cols-1 row-cols-md-5">
                <div class="col mb-3">
                    <div class="card h-100" (click)="openProducts('taximetros')">
                        <div class="card-body">
                            <img src="../../../assets/img/taximeter.png" class="img-thumbnail" alt="">
                            <h5 class="card-title">TAXÍMETROS</h5>
                            <button class="btn-more"> Ver mas...</button>
                        </div>
                    </div>
                </div>
                <div class="col mb-3">
                    <div class="card h-100" (click)="openProducts('velocimetros')">
                        <div class="card-body">
                            <img src="../../../assets/img/speedometer.png" class="img-thumbnail" alt="">
                            <h5 class="card-title">VELOCÍMETROS</h5>
                            <button class="btn-more"> Ver mas...</button> </div>
                    </div>
                </div>
                <div class="col mb-3">
                    <div class="card h-100" (click)="openProducts('avisos')">
                        <div class="card-body">
                            <img src="../../../assets/img/aviso.png" class="img-thumbnail" alt="">
                            <h5 class="card-title">AVISOS</h5>
                            <button class="btn-more"> Ver mas...</button> </div>
                    </div>
                </div>
                <div class="col mb-3">
                    <div class="card h-100" (click)="openProducts('gps')">
                        <div class="card-body">
                            <img src="../../../assets/img/taxi.png" class="img-thumbnail" alt="">
                            <h5 class="card-title">GPS</h5>
                            <button class="btn-more"> Ver mas...</button> </div>
                    </div>
                </div>
                <div class="col mb-3">
                    <div class="card h-100" (click)="openProducts('soporte')">
                        <div class="card-body">
                            <img src="../../../assets/img/worker.png" class="img-thumbnail" alt="">
                            <h5 class="card-title">SERVICIO TÉCNICO</h5>
                            <button class="btn-more"> Ver mas...</button> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>