<app-navbar></app-navbar>
<div class="container">
    <div class="col-12 mt-5">
        <h2>
            {{titulo | titlecase}}
        </h2>
        <hr>
        <div class="row row-cols-1 row-cols-md-3">
            <div class="col mb-4" *ngFor="let item of productos">
                <div class="card h-100 producto" (click)="detalle(item)">
                    <img [src]="item[0].imagen" class="card-img-top" alt="...">
                    <div class="card-body">
                    </div>
                    <div class="card-footer text-center">
                        <h3 class="card-title"><b>{{item[0].nombre | titlecase}}</b></h3>
                        <h4 class="card-text">${{item[0].precio | number}}</h4>
                        <button class="btn btn-dark btn-block">Ver Detalle</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>