import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { CatalogComponent } from './components/catalog/catalog.component';
import { LocationComponent } from './components/location/location.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { ProductoComponent } from './pages/producto/producto.component';
import { ComprasComponent } from './pages/compras/compras.component';
import { AdminComponent } from './administrador/admin/admin.component';
import { AdministracionComponent } from './administrador/administracion/administracion.component';
import { MenuComponent } from './administrador/menu/menu.component';
import {NgxPrintModule} from 'ngx-print';
import { VentasComponent } from './administrador/ventas/ventas.component';
import { ConsecutivoComponent } from './administrador/consecutivo/consecutivo.component';
import { PedidosComponent } from './administrador/pedidos/pedidos.component';
import { HistorialComponent } from './administrador/historial/historial.component';
import { FilterPipe } from './pipes/filter.pipe';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { ClientesComponent } from './administrador/clientes/clientes.component';
import { NuevoClienteComponent } from './administrador/nuevo-cliente/nuevo-cliente.component';
import { FacturasComponent } from './administrador/facturas/facturas.component';
import { OldlistComponent } from './administrador/oldlist/oldlist.component';
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    InicioComponent,
    CatalogComponent,
    LocationComponent,
    FooterComponent,
    ProductosComponent,
    ProductoComponent,
    ComprasComponent,
    AdminComponent,
    AdministracionComponent,
    MenuComponent,
    VentasComponent,
    ConsecutivoComponent,
    PedidosComponent,
    HistorialComponent,
    FilterPipe,
    ClientesComponent,
    NuevoClienteComponent,
    FacturasComponent,
    OldlistComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
