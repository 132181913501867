<app-menu></app-menu>
<div class="container-fluid mt-5">
    <div *ngIf="!dia" class="text-center">
        <button class="btn btn-primary btn-lg" (click)="iniciarJornada()">Iniciar Facturación</button>
    </div>
    <div *ngIf="dia">
        <div class="col-12">
            <div class="row">
                <div class="col-5 text-left">
                    <h3>
                        <img src="../../../assets/img/cuenta.png" width="50" class="img-fluid" alt="Responsive image"> Microedicar
                    </h3>
                </div>
                <div class="col-4 text-right">
                    <input (change)="buscaInterno($event.target.value)" style="font-size: 38px; width: 70%; text-align: center;" type="text">
                    <button (click)="buscarVehiculo()" style="margin-top: -15px;" class="btn btn-warning"><img src="../../../assets/img/buscar.png" width="50" alt=""></button>
                </div>
                <div class="col-3 text-right">
                    <button *ngIf="cambioTarifa" class="btn btn-danger" (click)="desactivarCambio()"><img src="../../../assets/img/cuenta.png" width="50" class="img-fluid" alt="Responsive image"> Desactivar Cambio de Tarifa</button>
                    <button *ngIf="!cambioTarifa" class="btn btn-warning" (click)="activarCambio()"><img src="../../../assets/img/cuenta.png" width="50" class="img-fluid" alt="Responsive image"> Activar Cambio de Tarifa</button>
                </div>
            </div>
            <hr>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-lg-6 info mr-1">
                    <div class="row">
                        <div class="col-6">
                            <h3>Factura de Venta: </h3>
                            <h4>Fecha:</h4>
                            <h4>Placa:</h4>
                            <h4>Interno:</h4>
                            <hr>
                            <h5>Cliente:</h5>
                            <h5>Cédula:</h5>
                            <h5>Teléfono:</h5>
                            <h5>Dirección:</h5>
                        </div>
                        <div class="col-6 text-right">
                            <b><h2 style="color: rgb(238, 6, 6);">{{factura}}</h2></b>
                            <h4>{{fecha | date}}</h4>
                            <h4><b style="color: blue;">{{placaVehiculo | uppercase}}</b></h4>
                            <h4><b style="color: rgb(61, 61, 65);">{{internoVehiculo | uppercase}}</b></h4>
                            <h5><b>{{nombreCliente | titlecase}}</b></h5>
                            <p>{{identificacion}}</p>
                            <p>{{phone}}</p>
                            <p>{{dir}}</p>
                        </div>
                    </div>
                    <div class="col-12 text-right">
                        <h2 style="color: blue;">${{liquidacion | number}}</h2>
                    </div>
                    <table class="table table-sm">
                        <thead class="thead-dark text-center">
                            <tr>
                                <th scope="col">CANTIDAD</th>
                                <th scope="col">DETALLE DEL PRODUCTO</th>
                                <th scope="col">VALOR</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center" *ngFor="let item of facturador; let i = index">
                                <td>{{item.cantidad}}</td>
                                <td>{{item.producto}}</td>
                                <td>${{item.precio | number}}</td>
                                <td>
                                    <button class="btn btn-danger" (click)="borrarItem(i, item.precio, item.cantidad)"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-5 mt-3">
                    <div class="col-12 text-right">
                        <button class="btn btn-danger btn-lg">${{saldo | number}}</button>
                    </div>
                    <div *ngIf="editarDatosCliente" class="col-12 text-right">
                        <button (click)="editarCliente()" title="Editar datos del cliente" class="btn btn-dark mt-1"><i class="fa fa-edit fa-lg"></i></button>
                    </div>
                    <div *ngIf="formuFact">
                        <input type="text" class="form-control" placeholder="Nombre cliente" (change)="nombre($event.target.value)">
                        <div class="row mt-2">
                            <div class="col-6">
                                <input type="number" class="form-control" placeholder="Cédula" (change)="cedula($event.target.value)">
                            </div>
                            <div class="col-6">
                                <input type="number" class="form-control" placeholder="Teléfono" (change)="telefono($event.target.value)">
                            </div>
                        </div>
                        <input type="text" class="form-control mt-2" placeholder="Dirección" (change)="direccion($event.target.value)">
                        <div class="row mt-2">
                            <div class="col-6">
                                <input type="text" class="form-control mt-2" placeholder="Placa del vehículo" (change)="placa($event.target.value)">
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control mt-2" placeholder="Número Interno" (change)="interno($event.target.value)">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="cambioTarifa">
                        <div class="row mt-2">
                            <div class="col-6">
                                <input type="number" value="1" min="1" class="form-control text-center" (change)="cantCambiosTarifa($event.target.value)">
                            </div>
                            <div class="col-6 text-right">
                                <button class="btn btn-dark" (click)="cambiotf()">Cambio de tarifa</button>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="mt-2">
                        <input type="text" placeholder="Producto / Servicio" class="form-control" (change)="itemFactura($event.target.value)">
                        <input type="number" placeholder="Ingrese la Temperatura del Cliente" class="form-control mt-2" (change)="temperatura($event.target.value)">
                        <div class="row mt-2">
                            <div class="col-6">
                                <input type="number" value="1" min="1" class="form-control text-center" (change)="cantidadItem($event.target.value)">
                            </div>
                            <div class="col-6">
                                <input type="number" min="1" placeholder="$ Valor" class="form-control text-center" (change)="costoItemSeleccionado($event.target.value)">
                            </div>
                        </div>
                        <button class="btn btn-dark btn-block mt-2" (click)="agregarItemFactura()">Agregar a la factura</button>
                        <div *ngIf="!facTipo" class="row">
                            <div class="col-6">
                                <button class="btn btn-success btn-block mt-2" (click)="tipoFactura('Contado')">Contado</button>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-primary btn-block mt-2" (click)="tipoFactura('Credito')">Crédito</button>
                            </div>
                        </div>
                        <button *ngIf="facTipo" class="btn btn-warning btn-block mt-2" (click)="facturacion()" data-toggle="modal" data-target="#factura">Finalizar Factura</button>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div *ngIf="factr" class="modal fade" id="factura" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Factura: No.<b>{{factura}}</b></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="col-12">
                    <p><b>{{fecha | date}}</b></p>
                    <hr>
                    <div class="row">
                        <div class="col-6 text-left">
                            <p>Placa Vehículo:</p>
                            <p>Interno:</p>
                            <p>Cliente:</p>
                            <p>Identificación:</p>
                            <p>Dirección:</p>
                            <p>Teléfono:</p>
                            <p>Temperatura:</p>
                            <p>Tipo de Venta:</p>
                        </div>
                        <div class="col-6 text-right">
                            <p><b>{{placaVehiculo}}</b></p>
                            <p><b>{{internoVehiculo}}</b></p>
                            <p>{{nombreCliente}}</p>
                            <p>C.C{{identificacion}}</p>
                            <p>{{dir}}</p>
                            <p>{{phone}}</p>
                            <p>{{tempt}}°C</p>
                            <p><b>{{tipoVenta | uppercase}}</b></p>
                        </div>
                    </div>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Producto</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Precio</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of facturador">
                            <td>{{item.producto}}</td>
                            <td>{{item.cantidad}}</td>
                            <td>{{item.precio}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-dark" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-warning" (click)="imprimirFactura()" printSectionId="print-section" ngxPrint><i class="fa fa-print">Imprimir Factura</i></button>
            </div>
        </div>
    </div>
</div>
<div style="width: 100%;display: none;" id="print-section">
    <div align='center'>
        <p><b style="font-size: 17px;">Servi-Taxímetros Edicar Microled</b> <br> NIT: 66826023-5 <br> Calle 33 No. 6 - 24 Pereira <br> Tel. (6) 3292397 <br>315 3227979</p>
    </div>
    <hr>
    <div>
        <table style="width: 100%;">
            <tbody>
                <tr>
                    <td>Fecha Facturación:</td>
                    <td><b>{{fecha | date}}</b></td>
                </tr>
                <tr>
                    <td>Factura Venta:</td>
                    <td><b>No.{{factura}}</b></td>
                </tr>
                <tr>
                    <td>Placa:</td>
                    <td><b>{{placaVehiculo}}</b></td>
                </tr>
                <tr>
                    <td>Interno:</td>
                    <td><b>{{internoVehiculo}}</b></td>
                </tr>
                <tr>
                    <td>Cliente:</td>
                    <td><b>{{nombreCliente}}</b></td>
                </tr>
                <tr>
                    <td>Identificacion:</td>
                    <td><b>C.C {{identificacion}}</b></td>
                </tr>
                <tr>
                    <td>Dirección:</td>
                    <td><b>{{dir}}</b></td>
                </tr>
                <tr>
                    <td>Teléfono:</td>
                    <td><b>{{phone}}</b></td>
                </tr>
                <tr>
                    <td>Temperatura:</td>
                    <td><b>{{tempt}}°C</b></td>
                </tr>
            </tbody>
        </table>
        <hr>
        <table style="width: 100%;border: 1px solid rgb(75, 74, 74);">
            <tbody>
                <tr>
                    <td><b>Producto</b></td>
                    <td><b>Cantidad</b></td>
                    <td><b>Precio</b></td>
                </tr>
                <tr *ngFor="let item of facturador">
                    <td>{{item.producto}}</td>
                    <td>{{item.cantidad}}</td>
                    <td>{{item.precio}}</td>
                </tr>
            </tbody>
        </table>
        <div>
            <h3 style="float: right;"><b>TOTAL: ${{liquidacion | number}}</b></h3>
        </div>
        <br>
        <br>
        <br>
        <br>
        <div align='center'>
            <p style="font-size: 8px;">Esta Factura de Venta se asimila en todos sus efectos legales a una letra de cambio según Art 779 del códifo de comercio. Modificado Art 5 ley 1231 de 2008. De acuerdo con la ley 1943 del 28 de Dic 2018.</p>
        </div>
    </div>
</div>
