<app-menu></app-menu>
<div class="container-fluid mt-5">
    <div class="col-12">
        <div class="row">
            <div class="col-6">
                <h3>
                    <img src="../../../assets/img/monitor.png" width="70"> Listado de Pedidos:
                </h3>
            </div>
            <div class="col-6 text-right">
                <button (click)="filtrar('En Envio')" class="btn btn-light"><img src="../../../assets/img/checked.png" width="40" alt=""></button>
                <button (click)="filtrar('Pendiente')" class="btn btn-light ml-1"><img src="../../../assets/img/list.png" width="40" alt=""></button>
            </div>

        </div>
    </div>
    <hr>
    <div class="mt-2">
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Fecha Compra</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Medio Pago</th>
                    <th scope="col">Referencia</th>
                    <th scope="col">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of pedidos">
                    <th>{{item.fecha | date}}</th>
                    <td>{{item.estado}}</td>
                    <td>{{item.medioPago}}</td>
                    <td>{{item.refCompra}}</td>
                    <td>
                        <button class="btn btn-success" (click)="detalleVenta(item)" data-toggle="modal" data-target="#factura" title="Detalle de Factura"><i class="fa fa-list"></i></button>
                        <button *ngIf="item.estado == 'Pendiente'" class="btn btn-primary ml-1" (click)="aceptarPedido(item.id)" title="Acptar Pedido"><i class="fa fa-check"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal fade" id="factura" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    <h4>Cliente: <b>{{cliente}}</b></h4>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div style="width: 100%;">
                    <h5 class="sombra">Teléfono: <b style="float: right;">{{telefono}}</b></h5>
                    <h5 class="sombra">E-mail: <b style="float: right;">{{email}}</b></h5>
                    <hr>
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col">Producto</th>
                                <th scope="col">Cantidad</th>
                                <th scope="col">Precio</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of carrito">
                                <td>{{item.nombre}}</td>
                                <td>{{item.cant}}</td>
                                <td>{{item.precio}}</td>
                                <td>{{item.total}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>