import { Component, OnInit } from '@angular/core';
import { ProductosService } from 'src/app/services/productos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-oldlist',
  templateUrl: './oldlist.component.html',
  styleUrls: ['./oldlist.component.scss']
})
export class OldlistComponent implements OnInit {

  credito = true;
  filterProducto = '';
  ventas: any;
  lista: any;
  cliente: string;
  telefono: string;
  direccion: string;
  identificacion: string;
  factura: number;
  idFact: string;
  placa: string;
  valorTotal: number;
  estado: string;
  saldo = 0;
  productos: any;
  fechaCompra: number;
  interno: string;
  tipo: string;
  temperatura: string;
  jornada: any;
  saldoCaja: number;
  factInicio: number;
  factFin: number;
  fechaJornada: number;

  codFac: any;

  constructor(private prodSvc: ProductosService) { }

  ngOnInit(): void {
    this.codJornada();
  }

  codJornada(){
    if (localStorage.getItem('dataListOldTickPereira')) {
      this.ventas = JSON.parse( localStorage.getItem('dataListOldTickPereira')!);
      console.log('Lectura desde local');
    } else {
      this.prodSvc.cargarVentas()
      .subscribe(res => {
        this.ventas = res;
        console.log('Lectura desde BD');
        localStorage.setItem('dataListOldTickPereira', JSON.stringify(this.ventas));
      });
    }
  }

  searchId(cedula: string){
    this.prodSvc.cargarVentasPorCedula(cedula)
                .subscribe(res => {
                  this.ventas = res;
                });
  }

  searchPlc(placa: string){
    this.prodSvc.cargarVentasPorPlaca(placa)
                .subscribe(res => {
                  this.ventas = res;
                });
  }

  detalleVenta(venta: any){
    // console.log(venta);
    this.idFact = venta.id;
    this.factura = venta.factura;
    this.cliente = venta.cliente;
    this.identificacion = venta.cedula;
    this.telefono = venta.telefono;
    this.direccion = venta.direccion;
    this.productos = venta.productos;
    this.fechaCompra = venta.fechaCompra;
    this.placa = venta.placa;
    this.estado = venta.estado;
    this.valorTotal = venta.valorTotal;
    this.interno = venta.interno;
    this.temperatura = venta.temperatura;
    this.tipo = venta.tipo;
  }

  verListaCompleta(){
    this.ventas = [];
    this.ventas = this.lista;
  }

  creditosPendientes(){
    this.credito = false;
    this.ventas = [];
    this.prodSvc.cargarCreditosPendientes()
                .subscribe(res => {
                  this.ventas = res;
                });
  }

  /* pagarCreditoPendiente(factura: any){
    const newSald = factura.valorTotal + this.saldoCaja;
    Swal.fire({
      title: 'Está seguro?',
      text: 'Se va a pagar la factura seleccionada!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Pagar Factura!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prodSvc.pagarCreditoPendiente(factura.id).then(() => {
          this.prodSvc.nuevoSaldoVentas(newSald);
        });
        Swal.fire(
          'Pagada!',
          'La factura ha sido pagada correctamente.',
          'success'
        );
      }
    });
  } */

  anularVenta(venta: any){
    console.log(venta);
    let total = this.saldoCaja - venta.valorTotal

    Swal.fire({
      title: 'está seguro?',
      text: "Se va a anular esta factura!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, anular!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prodSvc.anularFactura(venta.id, venta.factura, total).then(() => {
          Swal.fire(
            'Anulada!',
            'Factura de venta anulada correctamente.',
            'success'
          );
        });
      }
    })
  }

}
