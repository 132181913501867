<app-menu></app-menu>
<div class="container mt-5">
    <h3><img src="../../../assets/img/nuevo.png" width="90" alt=""> Agregar Nuevo Cliente:</h3>
    <hr>
    <form [formGroup]="newClientForm" (ngSubmit)="addNewClient(newClientForm.value)" autocomplete="off">
        <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Nombre del Cliente:</label>
                        <input type="text" formControlName="nombre" class="form-control" [class.is-invalid]="nombreNoValido" placeholder="Ingrese el nombre">
                        <small *ngIf="nombreNoValido" class="text-danger">Debe ingresar el nombre</small>
                    </div>
                    <div class="form-group">
                        <label>Cédula:</label>
                        <input type="text" formControlName="cedula" class="form-control" [class.is-invalid]="cedulaNoValido" placeholder="Ingrese número de cédula">
                        <small *ngIf="cedulaNoValido" class="text-danger">Debe ingresar el número de cédula</small>
                    </div>
                    <div class="form-group">
                        <label>Teléfono:</label>
                        <input type="text" formControlName="telefono" class="form-control" [class.is-invalid]="telefonoNoValido" placeholder="Ingrese el teléfono">
                        <small *ngIf="telefonoNoValido" class="text-danger">Debe ingresar el teléfono</small>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Placa del Vehículo:</label>
                        <input type="text" formControlName="placa" class="form-control" [class.is-invalid]="placaNoValido" placeholder="Ingrese la Placa">
                        <small *ngIf="placaNoValido" class="text-danger">Debe ingresar la placa</small>
                    </div>
                    <div class="form-group">
                        <label>Interno del Vehículo:</label>
                        <input type="text" formControlName="interno" class="form-control" [class.is-invalid]="internoNoValido" placeholder="Ingrese número interno">
                        <small *ngIf="internoNoValido" class="text-danger">Debe ingresar el número interno</small>
                    </div>
                    <div class="form-group">
                        <label>Dirección:</label>
                        <input type="text" formControlName="direccion" class="form-control" [class.is-invalid]="direccionNoValido" placeholder="Ingrese la dirección">
                        <small *ngIf="direccionNoValido" class="text-danger">Debe ingresar el teléfono</small>
                    </div>
                </div>
            </div>
            <div class="text-center mt-2">
                <button type="submit" class="btn btn-warning btn-lg">Guardar Cliente</button>
            </div>
        </div>
    </form>
</div>