<app-navbar></app-navbar>
<div class="container-fluid mt-5">
    <div class="col-12">
        <h2>
            <img src="../../../assets/img/monitor.png" width="70" class="img-fluid" alt="Responsive image"> Productos Seleccionados:
        </h2>
        <hr>
        <div class="row">
            <div class="col-8" style="border-right: solid;border-width: 1px; border-color: rgb(151, 151, 151);">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="text-center">Producto</th>
                            <th class="text-center"></th>
                            <th class="text-center">Cantidad</th>
                            <th class="text-center">Precio</th>
                            <th class="text-center">Total</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of lista; let i = index">
                            <td class="text-center"><img [src]="item.imagen" width="70" class="img-fluid" alt="Responsive image"></td>
                            <td class="text-center"><b>{{item.nombre}}</b></td>
                            <td class="text-center">{{item.cant}}</td>
                            <td class="text-center">${{item.precio | number}}</td>
                            <td class="text-center"><b>${{item.total | number}}</b></td>
                            <td class="text-center">
                                <button class="btn btn-danger" (click)="deleteItem(i)"><i class="fa fa-trash" aria-hidden="true"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-4 mt-1">
                <h4 class="text-center">Resumen de compra</h4>
                <hr>
                <div class="col-12">
                    <div class="row">
                        <div class="col-6 text-left">
                            <h5>Subtotal:</h5>
                            <h5>Flete:</h5>
                            <h5>Descuento:</h5>
                            <hr>
                            <h2>Total:</h2>
                        </div>
                        <div class="col-6 text-right">
                            <h5><b style="color: rgb(27, 8, 134);">${{liquidacion | number}}</b></h5>
                            <h5><b>${{flete | number}}</b></h5>
                            <h5><b>${{descuento | number}}</b></h5>
                            <hr>
                            <h2><b style="color: rgb(172, 2, 2);">${{liquidacion | number}}</b></h2>
                        </div>
                    </div>
                    <hr>
                    <button class="btn btn-warning btn-block" [routerLink]="['/home']"><i class="fa fa-undo fa-lg"></i> Seguir Comprando</button>
                    <button class="btn btn-dark btn-block" data-toggle="modal" data-target="#staticBackdrop"><i class="fa fa-credit-card fa-lg"></i> Realizar Pago</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Finalización de la compra</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6" style="border-right: solid; border-width: 1px;">
                            <h3>Iniciar Sesión o registro</h3>
                            <p>Al estar registrado puedes acceder a descuentos, envíos sin costo y recibir nuestras novedades.</p>
                            <hr>
                            <form>
                                <div class="form-group">
                                    <label for="email">E-mail:</label>
                                    <input type="email" class="form-control" placeholder="Ingrese su email">
                                </div>
                                <div class="form-group">
                                    <label for="email">Contraseña:</label>
                                    <input type="password" class="form-control" placeholder="Ingrese su contraseña">
                                </div>
                                <button class="btn btn-dark btn-block">Iniciar Sesión</button>
                                <button class="btn btn-dark btn-block">Crear Cuenta</button>
                            </form><br>
                            <div class="col-12 text-center ">
                                <h5>Ingresa con tu cuenta de:</h5>
                                <br>
                                <div class="row">
                                    <div class="col-6">
                                        <button class="btn btn-danger btn-block redes"><i class="fa fa-google fa-2x"></i></button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-primary btn-block redes"><i class="fa fa-facebook fa-2x"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <h3>Realizar Compra sin registro</h3>
                            <form [formGroup]="dataCliente" (ngSubmit)="datosCliente(dataCliente.value)">
                                <div class="form-group">
                                    <label for="email">Nombre completo:</label>
                                    <input type="text" class="form-control" placeholder="Ingrese su nombre" formControlName="nombre" [class.is-invalid]="nombreNoValido">
                                    <small class="eml" *ngIf="nombreNoValido">Ingrese su nombre completo</small>
                                </div>
                                <div class="form-group">
                                    <label>Teléfono de contacto</label>
                                    <input type="text" class="form-control" placeholder="Ingrese su número telefónico" formControlName="telefono" [class.is-invalid]="telefonoNoValido">
                                    <small class="eml" *ngIf="telefonoNoValido">Ingrese su nombre completo</small>
                                </div>
                                <div class="form-group">
                                    <label for="email">E-mail:</label>
                                    <input type="email" class="form-control" placeholder="Ingrese su email" formControlName="email" [class.is-invalid]="emailNoValido">
                                    <small class="eml" *ngIf="emailNoValido">Ingrese su nombre completo</small>
                                </div>
                                <button type="submit" class="btn btn-dark btn-block">Confirmar Datos</button>
                            </form>
                            <p>Puede realizar el pago directamente por diferentes medios (tarjeta de crédito, PSE, balotto, efecty), o puede realizar el pedido y convenir el pago con uno de nuestros asesores que se comunicará con usted.</p>
                            <form #form method="post" action="https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/">
                                <input name="merchantId" type="hidden" value="${this.merchantid}">
                                <input name="accountId" type="hidden" value="${this.accountId}">
                                <input name="description" type="hidden" value="Pago MicroTaxímetros Edicar">
                                <input name="referenceCode" type="hidden" value="${this.reference}">
                                <input name="amount" type="hidden" value="${this.liquidacion}">
                                <input name="tax" type="hidden" value="0">
                                <input name="taxReturnBase" type="hidden" value="0">
                                <input name="currency" type="hidden" value="COP">
                                <input name="signature" type="hidden" value="${signature}">
                                <input name="test" type="hidden" value="1">
                                <input name="buyerEmail" type="hidden" value="${this.email}">
                                <input name="responseUrl" type="hidden" value="https://torreonmarket-25713.web.app/#/confirmacion">
                                <input name="confirmationUrl" type="hidden" value="http://www.test.com/confirmation">
                                <!-- <button type="submit" *ngIf="request" class="btn btn-dark btn-block" (click)="form.submit()">
                                    <img src="../../../assets/img/logo_payu.png" width="60" class="img-fluid" alt="Responsive image"> 
                                    Realizar Pago en Linea
                                </button> -->
                                <button *ngIf="request" class="btn btn-dark btn-block" (click)="provisional()">
                                    <img src="../../../assets/img/logo_payu.png" width="60" class="img-fluid" alt="Responsive image"> 
                                    Realizar Pago en Linea
                                </button>
                                <button *ngIf="request" (click)="solicitudCompra()" class="btn btn-warning btn-block" data-dismiss="modal"><img src="../../../assets/img/asesor.png" width="30" class="img-fluid" alt="Responsive image"> Pago con Asesor</button>
                                <button *ngIf="!request" class="btn btn-dark btn-block" disabled><img src="../../../assets/img/logo_payu.png" width="60" class="img-fluid" alt="Responsive image"> Realizar Pago en Linea</button>
                                <button *ngIf="!request" class="btn btn-warning btn-block" disabled><img src="../../../assets/img/asesor.png" width="30" class="img-fluid" alt="Responsive image"> Pago con Asesor</button>
                            </form>`;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>