<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/home']">Inicio <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/administracion']">Servi-Taxímetros</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/ventas']">Facturas</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/consecutivo']">Consecutivo</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/pedidos']">Pedidos</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/historial']">Control Jornada</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/listaAnterior']">Listado</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/clientes']">Clientes</a>
            </li>
        </ul>
        <div class="text-right">
            <button *ngIf="dia" class="btn btn-outline-warning" (click)="finalizarJornada()">Finalizar Jornada</button>
        </div>
    </div>
</nav>
