import { Component, OnInit } from '@angular/core';
import { ProductosService } from '../../services/productos.service';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Md5 } from 'md5-typescript';
import { Router } from '@angular/router';

@Component({
  selector: 'app-compras',
  templateUrl: './compras.component.html',
  styleUrls: ['./compras.component.scss']
})
export class ComprasComponent implements OnInit {

  lista: any;
  liquidacion: number;
  flete = 0;
  descuento = 0;
  request = false;
  sale = true;
  compra: any = {
    cliente: [],
    carrito: [],
    fecha: new Date().getTime(),
    refCompra: '',
    estado: 'Pendiente',
    medioPago: ''
  };

  apiKey = '4Vj8eK4rloUd272L48hsrarnUA';
  merchantid = '508029';
  accountId = '512321';
  cifrado: string;
  reference: number;
  signature: any;

  dataCliente = new FormGroup ({
    nombre: new FormControl('', Validators.required),
    telefono: new FormControl('', [Validators.required, Validators.minLength(8)]),
    email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
  });

  get emailNoValido() {
    return this.dataCliente.get('email').invalid && this.dataCliente.get('email').touched;
  }
  get nombreNoValido() {
    return this.dataCliente.get('nombre').invalid && this.dataCliente.get('nombre').touched;
  }
  get telefonoNoValido() {
    return this.dataCliente.get('telefono').invalid && this.dataCliente.get('telefono').touched;
  }

  constructor(private prodSvc: ProductosService,
              private router: Router) { }

  ngOnInit(): void {
    this.loadStorage();
    this.calcularCosto();
  }

  calcularCosto(){
    let saldo = 0;
    for (const key in this.lista){
      saldo = this.lista[key].total + saldo;
    }
    this.liquidacion = saldo;
    if (this.liquidacion === 0) {
      Swal.fire(
        'Sin productos para comprar',
        'Su carrito de compras esta vacío',
        'success'
      );
      this.router.navigate(['home']);
    }
  }

  loadStorage(){
    if (localStorage.getItem('data')) {
      this.lista = JSON.parse( localStorage.getItem('data'));
    }
  }

  deleteItem(item: number){
    Swal.fire({
      title: 'Está seguro?',
      text: 'Se eliminará este producto de la lista!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrarlo!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.lista.splice(item, 1);
        this.prodSvc.updateStorage(this.lista);
        this.calcularCosto();
        Swal.fire(
          'Eliminado!',
          'El producto ya no esta en tu carrito.',
          'success'
        );
      }
    });
  }

  datosCliente(clt: any){
    if (this.sale) {
      if (this.dataCliente.invalid){
        Swal.fire({
          title: 'Error...',
          text: 'Debe ingresar la información requerida',
          icon: 'error',
          allowOutsideClick: false,
          showCloseButton: true
        });
        return Object.values( this.dataCliente.controls ).forEach( control => {
          if ( control instanceof FormGroup ) {
            // tslint:disable-next-line: no-shadowed-variable
            Object.values( control.controls ).forEach( control => control.markAsTouched() );
          } else {
            control.markAsTouched();
          }
        });
      }
      this.reference = Math.ceil(Math.random() * 987524);
      this.signature = Md5.init(`${this.apiKey}~${this.merchantid}~${this.reference}~${this.liquidacion}~COP`);
      this.request = true;
      const fecha = new Date().getTime();
      this.reference = Math.ceil(Math.random() * 987524);
      this.compra.cliente.push(clt);
      this.compra.carrito = this.lista;
      this.compra.refCompra = this.reference;
      console.log(this.compra);
    } else {
      Swal.fire(
        'Venta en proceso',
        'Los datos ya se confirmaron, seleccione tipo de pago para continuar',
        'question'
      );
    }
  }

  solicitudCompra(){
    Swal.fire(
      'Pedido enviado',
      'Un asesor se comunicará para finalizar el envio de los productos',
      'success'
    );
    this.compra.medioPago = 'SolicitudAsesor';
    this.prodSvc.cargarSolicitudCompra(this.compra)
                .then(res => {
                  if (res) {
                    this.sale = false;
                    this.liquidacion = 0;
                    this.compra = [];
                    localStorage.setItem('data', JSON.stringify(this.compra));
                    if (this.liquidacion === 0) {
                      this.router.navigate(['home']);
                    }
                  }
                });
  }

  provisional(){
    Swal.fire(
      'Pasarela de pago en proceso',
      'Por favor realice el pago por medio de asesor, pronto tendremos disponible el pago en línea',
      'success'
    );
  }
}
