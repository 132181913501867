<app-navbar></app-navbar>
<div class="container">
    <div class="col-12 mt-5">
        <h2>Producto: <b>{{nombre | titlecase}}</b></h2>
        <hr>
        <div class="text-center mt-5">
            <img [src]="imagen" class="img-fluid" alt="Responsive image">
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-6">
                <div class="card h-100">
                    <div class="card-body">
                        <h5 class="card-title">Descripción:</h5>
                        <p class="card-text">{{descripcion}}</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card h-100">
                    <div class="card-body">
                        <h5 class="card-title text-center">{{nombre | titlecase}}</h5>
                        <h1 class="card-text text-center"><b>${{precio | number}}</b></h1>
                        <div class="col-12">
                            <label for="">Indique la cantidad:</label>
                            <div class="row cantidad">
                                <button (click)="cantidadProducto('restar')" class="btn btn-outline-dark"><b>-</b></button>
                                <input class="form-control text-center" [value]="cant" min="1" disabled>
                                <button (click)="cantidadProducto('sumar')" class="btn btn-outline-dark"><b>+</b></button>
                            </div>
                        </div><br>
                        <button *ngIf="!soporte" class="btn btn-dark btn-block" (click)="addCarShop()"><i class="fa fa-shopping-cart fa-lg"></i>&nbsp;COMPRARLO</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>