import { Component, OnInit } from '@angular/core';
import { ProductosService } from 'src/app/services/productos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-consecutivo',
  templateUrl: './consecutivo.component.html',
  styleUrls: ['./consecutivo.component.scss']
})
export class ConsecutivoComponent implements OnInit {

  newConsecutivo: number;
  newPrecioCt: number;
  constructor(private prodSvc: ProductosService) { }

  ngOnInit(): void {
  }

  consecutivo(event: number){
    this.newConsecutivo = event;
  }

  guardaConsecutivo(){
    Swal.fire({
      title: 'Está seguro?',
      text: 'Se va a actualizar el consecutivo de factura iniciando en:' + '' + this.newConsecutivo,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, actualizar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prodSvc.actualizarConsecutivo(Number(this.newConsecutivo))
                    .then(res => {
                      Swal.fire(
                        'Actualizado!',
                        'El consecutivo de factura ha sido actualizado.',
                        'success'
                      );
                      window.location.reload();
                    });
      }
    });
  }

  cambioTarifaPrecio(event: number){
    this.newPrecioCt = event;
  }

  precioTarifa(){
    Swal.fire({
      title: 'Está seguro?',
      text: 'Se va a actualizar el precio del cambio de tarifa',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, actualizar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prodSvc.actualizarCambioTarifa(Number(this.newPrecioCt))
                    .then(res => {
                      Swal.fire(
                        'Actualizado!',
                        'El precio del cambio de tarifa ha sido actualizado.',
                        'success'
                      );
                      window.location.reload();
                    });
      }
    });
  }

}
