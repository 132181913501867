import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ProductosService } from '../../services/productos.service';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.scss']
})
export class PedidosComponent implements OnInit {
  pedidos: any;
  cliente: string;
  email: string;
  telefono: string;
  carrito: any;
  constructor(private prodSvc: ProductosService) { }

  ngOnInit(): void {
    this.prodSvc.cargarPedidos()
                .subscribe(res => {
                  this.pedidos = res;
                });
  }

  detalleVenta(pedido: any){
    this.cliente = pedido.cliente[0].nombre;
    this.email = pedido.cliente[0].email;
    this.telefono = pedido.cliente[0].telefono;
    this.carrito = pedido.carrito;
  }

  aceptarPedido(idPedido: string){
    Swal.fire({
      title: 'Está Seguro?',
      text: 'Se va a aceptar este pedido?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, aceptar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prodSvc.aceptarPedido(idPedido);
        Swal.fire(
          'Aceptado!',
          'El pedido debe ser enviado al cliente.',
          'success'
        );
      }
    });
  }

  filtrar(dato: string){
    this.prodSvc.filtrarPedidos(dato)
                .subscribe(res => {
                  this.pedidos = res;
                });
  }

}
