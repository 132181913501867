import { Component, OnInit } from '@angular/core';
import { ProductosService } from 'src/app/services/productos.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  dia: boolean;
  jornada: any;
  constructor(private prodSvc: ProductosService,
              private router: Router) { }

  ngOnInit(): void {
    this.prodSvc.jornadaEstado().then(std => {
      this.jornada = std;
      console.log();
      if (this.jornada[0].estado === 'cerrada') {
        this.dia = false;
      }
      if (this.jornada[0].estado === 'iniciado') {
        this.dia = true;
      }
    });
  }

  finalizarJornada(){
   Swal.fire({
      title: 'Está Seguro?',
      text: 'Se va a cerrar sesión',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Finalizar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prodSvc.FinalizarJornadaLaboral().then(jrd => {
          this.prodSvc.jornadaEstado().then(std => {
            this.jornada = std;
            console.log(this.jornada);
            this.prodSvc.jornadaHistorial(this.jornada[0])
                        .then(hrt => {
                           window.location.reload();
                        });
          });
        });
        Swal.fire(
          'Finalizado!',
          'La sesión ha terminado.',
          'success'
        );
      }
    });
  }

}
