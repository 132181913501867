<div class="location">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h2 id="contacto">
                    UBÍCANOS | Taxímetros Edicar
                    <hr>
                </h2>
            </div>
            <div class="col-md-12 mt-5">
                <div class="row row-cols-1 row-cols-md-2">
                    <div class="col mb-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Microtaxímetros Edicar Medellín</h5>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">
                                            <h5>Teléfonos:</h5>
                                            <h5><i class="fa fa-phone-square" aria-hidden="true"></i>&nbsp; Fijo: (4) 4507101</h5>
                                            <h5><i class="fa fa-mobile" aria-hidden="true"></i>&nbsp; Cel: 319 3159294</h5>
                                        </div>
                                        <div class="col-6">
                                            <h5>Dirección:</h5>
                                            <h5><i class="fa fa-map-marker" aria-hidden="true"></i> Calle 30A # 69 - 108 <br> Local 103</h5>
                                            <h5><i class="fa fa-building" aria-hidden="true"></i> Medellín.</h5>
                                        </div>
                                    </div>
                                </div><br>
                                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img src="../../../assets/img/microedicar.png" class="d-block w-100" alt="...">
                                        </div>
                                        <div class="carousel-item">
                                            <img src="../../../assets/img/microedicar2.jpeg" class="d-block w-100" alt="...">
                                        </div>
                                        <div class="carousel-item">
                                            <img src="../../../assets/img/microedicar3.jpeg" class="d-block w-100" alt="...">
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-4">
                        <div class="card">
                            <div class="card-body">
                                <br>
                                <h5 class="card-title">Ubicación</h5>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.2366524947547!2d-75.59233038589014!3d6.23250259548848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e4429b96b182933%3A0xbe289e9572bf3046!2sCalle%2030A%20%2369-108%2C%20Medell%C3%ADn%2C%20Antioquia!5e0!3m2!1ses-419!2sco!4v1615827292718!5m2!1ses-419!2sco"
                                    width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
