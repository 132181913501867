import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './pages/inicio/inicio.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { ProductoComponent } from './pages/producto/producto.component';
import { ComprasComponent } from './pages/compras/compras.component';
import { AdminComponent } from './administrador/admin/admin.component';
import { AdministracionComponent } from './administrador/administracion/administracion.component';
import { VentasComponent } from './administrador/ventas/ventas.component';
import { ConsecutivoComponent } from './administrador/consecutivo/consecutivo.component';
import { PedidosComponent } from './administrador/pedidos/pedidos.component';
import { HistorialComponent } from './administrador/historial/historial.component';
import { ClientesComponent } from './administrador/clientes/clientes.component';
import { NuevoClienteComponent } from './administrador/nuevo-cliente/nuevo-cliente.component';
import { FacturasComponent } from './administrador/facturas/facturas.component';
import { OldlistComponent } from './administrador/oldlist/oldlist.component';


const routes: Routes = [
  { path: 'home', component: InicioComponent},
  { path: 'productos/:id', component: ProductosComponent},
  { path: 'producto/:id', component: ProductoComponent},
  { path: 'facturas/:inicio/:fin/:fecha', component: FacturasComponent},
  { path: 'compras', component: ComprasComponent},
  { path: 'admin', component: AdminComponent},
  { path: 'administracion', component: AdministracionComponent},
  { path: 'ventas', component: VentasComponent},
  { path: 'consecutivo', component: ConsecutivoComponent},
  { path: 'pedidos', component: PedidosComponent},
  { path: 'historial', component: HistorialComponent},
  { path: 'clientes', component: ClientesComponent},
  { path: 'nuevo', component: NuevoClienteComponent},
  { path: 'listaAnterior', component: OldlistComponent},
  { path: '**', pathMatch: 'full', redirectTo: '/home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
