import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ProductosService } from '../../services/productos.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nuevo-cliente',
  templateUrl: './nuevo-cliente.component.html',
  styleUrls: ['./nuevo-cliente.component.scss']
})
export class NuevoClienteComponent implements OnInit {
  newClientForm = new FormGroup({
    nombre: new FormControl('', Validators.required),
    cedula: new FormControl('', Validators.required),
    interno: new FormControl('', Validators.required),
    placa: new FormControl('', Validators.required),
    telefono: new FormControl('', Validators.required),
    direccion: new FormControl('', Validators.required)
  });

  get nombreNoValido() {
    return this.newClientForm.get('nombre').invalid && this.newClientForm.get('nombre').touched;
  }
  get cedulaNoValido() {
    return this.newClientForm.get('cedula').invalid && this.newClientForm.get('cedula').touched;
  }
  get internoNoValido() {
    return this.newClientForm.get('interno').invalid && this.newClientForm.get('interno').touched;
  }
  get placaNoValido() {
    return this.newClientForm.get('placa').invalid && this.newClientForm.get('placa').touched;
  }
  get telefonoNoValido() {
    return this.newClientForm.get('telefono').invalid && this.newClientForm.get('telefono').touched;
  }
  get direccionNoValido() {
    return this.newClientForm.get('direccion').invalid && this.newClientForm.get('direccion').touched;
  }

  constructor(private productoSvc: ProductosService,
              private router: Router) { }

  ngOnInit(): void {
  }

  addNewClient(client: any){
    console.log(client.placa);
    this.productoSvc.verificarPlacaCliente(client.placa)
                    .subscribe(res => {
                      if (res.length > 0) {
                        Swal.fire(
                          'Error',
                          'Este número de placa ya se encuentra registrado',
                          'error'
                        );
                      } else {
                        this.productoSvc.agregarCliente(client).then(() => {
                          Swal.fire(
                            'Agregado',
                            'Cliente creado correctamente',
                            'success'
                          );
                          this.router.navigate(['/clientes']);
                        });
                      }
                    });
  }

}
